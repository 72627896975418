<template>
  <section>
    <section class="top">
      <div>
        <p class="recp_title">
          Receipt
        </p>
        <p class="recp_subtitle">
          List Of Receipts
        </p>
      </div>

      <div>
        <div class="top_search">
          <VTextField
            v-model="search"
            :hide-details="true"
            label="Search Receipt By Name"
            append-icon="search"
            solo
            flat
          />
        </div>
      </div>
      <div class="top_btn">
        <VBtn
          round
          large
          class="shadow"
          color="white"
          to="receipt/new"
        >
          New Receipt
        </VBtn>
      </div>
    </section>
    <section class="content">
      <div class="recp_grid">
        <!-- Start Looping Data -->
        <div
          v-for="receipt in filteredReceipts"
          :key="receipt.id"
        >
          <VCard class="recp_card shadow">
            <VList two-line>
              <VListTile>
                <VListTileAvatar color="primary">
                  <span class="white--text">
                    <b> {{ receipt.name.charAt(0) }} </b>
                  </span>
                </VListTileAvatar>
                <VListTileContent>
                  <VListTileTitle> {{ receipt.name }} </VListTileTitle>
                  <VListTileSubTitle class="subtxt">
                    {{ receipt.admNo }}
                  </VListTileSubTitle>
                </VListTileContent>
                <VListTileAction>
                  <VListTileActionText class="subtxt">
                    {{ receipt.date }}
                  </VListTileActionText>

                  <VBtn
                    icon
                    color="white"
                    @click="viewRecp(receipt.id)"
                  >
                    <VIcon color="secondary">
                      visibility
                    </VIcon>
                  </VBtn>
                </VListTileAction>
              </VListTile>
            </VList>
          </VCard>
        </div>
      </div>
    </section>
    <VDialog
      v-model="isLoading"
      width="300"
      persistent
    >
      <VCard
        v-if="loadStatus === 'loading'"
        class="notification_card elevation-20"
      >
        <p>Loading</p>
        <span>Fetching Data, Please Wait...</span>
        <div class="notification_ico">
          <VProgressCircular
            indeterminate
            color="primary"
            :size="30"
            :width="3"
          />
        </div>
      </VCard>
      <VCard
        v-if="loadStatus === 'netfail'"
        class="notification_card elevation-20"
      >
        <p>Internet Issue</p>
        <span>Retrying In {{ retry }} Seconds</span>
        <div class="notification_ico">
          <VProgressCircular
            indeterminate
            color="red"
            :size="30"
            :width="3"
          />
        </div>
      </VCard>
      <VCard
        v-if="loadStatus === 'fail'"
        class="notification_card elevation-20"
      >
        <p>Error</p>
        <span>An Error Occured</span>
        <div class="notification_ico">
          <VBtn
            depressed
            round
            color="primary"
            @click="reloadPage()"
          >
            Reload
          </VBtn>
        </div>
      </VCard>
    </VDialog>
  </section>
</template>

<script>
import axios from 'axios';
import api from '../../conf/api';

export default {
  data: () => ({
    isLoading: true,
    loadStatus: '',
    retry: 0,
    search: '',
    receipts: [],
  }),
  computed: {
    filteredReceipts() {
      return this.receipts.filter(receipt => receipt.name.toLowerCase()
        .match(this.search.toLowerCase()));
    },
  },
  async beforeMount() {
    try {
      this.loadStatus = 'loading';
      await axios.get(`${api}/receipt/all`)
        .then((res) => {
          this.isLoading = false;
          if (res.data !== '') {
            this.receipts = res.data.data;
          }
        });
    } catch (e) {
      if (e.code === 'auth/network-request-failed') {
        this.loadStatus = 'netfail';
        this.retry = 10;
        setInterval(() => {
          this.retry -= 1;
        }, 1000);
        setTimeout(() => {
          window.location.reload();
        }, 9500);
      } else {
        this.loadStatus = 'fail';
      }
    }
  },
  methods: {
    reloadPage() {
      setTimeout(() => {
        window.location.reload();
      }, 400);
    },
    viewRecp(id) {
      setTimeout(() => {
        this.$router.push(`/receipt/${id}`);
      }, 150);
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '../../assets/stylus/Receipt/index';
</style>
